import Oidc from 'oidc-client';

export default {
    install: Vue => {
        if(!Vue.prototype.$config.DevSettings.ENABLED) {
        
        var mgr;
            if(Vue.prototype.$config.useLegacyAuth) {
                mgr = new Oidc.UserManager({ 
                    
                    authority: Vue.prototype.$config.OpenIdConfig.authority,
                    client_id: Vue.prototype.$config.OpenIdConfig.client_id,
                    redirect_uri: Vue.prototype.$config.OpenIdConfig.redirect_uri,
                    response_type: Vue.prototype.$config.OpenIdConfig.response_type,
                    scope: Vue.prototype.$config.OpenIdConfig.scope,
                    post_logout_redirect_uri: Vue.prototype.$config.OpenIdConfig.post_logout_redirect_uri,    
                    userStore: new Oidc.WebStorageStateStore({store: window.sessionStorage}),
                    automaticSilentRenew: Vue.prototype.$config.OpenIdConfig.automaticSilentRenew,
                    silent_redirect_uri: Vue.prototype.$config.OpenIdConfig.silent_redirect_uri,
                    accessTokenExpiringNotificationTime: Vue.prototype.$config.OpenIdConfig.accessTokenExpiringNotificationTime,
                    revokeAccessTokenOnSignout : Vue.prototype.$config.OpenIdConfig.revokeAccessTokenOnSignout,
                    monitorSession : Vue.prototype.$config.OpenIdConfig.monitorSession,
                    //accessTokenExpiringNotificationTime: -1
                })
            }
            else 
            {
                mgr = new Oidc.UserManager({
                    authority: Vue.prototype.$config.WhodisOpenIdConfig.authority,
                    client_id: Vue.prototype.$config.WhodisOpenIdConfig.client_id,
                    redirect_uri: Vue.prototype.$config.WhodisOpenIdConfig.redirect_uri,
                    response_type: Vue.prototype.$config.WhodisOpenIdConfig.response_type,
                    scope: Vue.prototype.$config.WhodisOpenIdConfig.scope,
                    post_logout_redirect_uri: Vue.prototype.$config.WhodisOpenIdConfig.post_logout_redirect_uri,   
                    automaticSilentRenew: Vue.prototype.$config.WhodisOpenIdConfig.automaticSilentRenew,
                    silent_redirect_uri: Vue.prototype.$config.WhodisOpenIdConfig.silent_redirect_uri
                })
            }
        
        
        
            mgr.events.addUserSignedOut(function () {
                mgr.removeUser()
            });
            mgr.events.addAccessTokenExpired(function(){
                mgr.signinRedirect()
            });

            Vue.prototype.mgr = mgr;
        }
    }
}