import axios from 'axios';

class ApiService {
    constructor(defaultOptions){
        this.service = axios.create(defaultOptions);

        this.service.interceptors.response.use((response) => {
            return response;
        }, function (error) {
            // Do something with response error
            if (error.response !== undefined) {
                if(error.response.status === 401) {
                    throw new Error("Unable to authenticate");
                }
                else if(error.response.status === 403) {
                    throw new Error("Unauthorized");
                }
            }
            return Promise.reject(error);
        });
    }
   
    get(route) {
        return this.service.get(route)
    }
    
    put(route, data) {
        return this.service.request({
          method: 'PUT',
          url: route,
          responseType: 'json',
          data: data
        });
    }

    delete(route, data) {
        return this.service.request({
          method: 'DELETE',
          url: route,
          responseType: 'json',
          data: data
        });
    }
    
    post(route, data) {
        return this.service.request({
          method: 'POST',
          url: route,
          responseType: 'json',
          data: data
        });
    }

    setDefaults(defaults){
        this.service.defaults = defaults
    }

    setDefaultToken(token){
        this.service.defaults.headers["Authorization"] = "Bearer " + token;
    }

    setDefaultBaseURL(url){
        this.service.defaults.baseURL = url
    }

    setDeaultHeaders(headers){
        this.service.defaults.headers = headers
    }
    setDefaultParams(params){
        this.service.defaults.params = params
    }
}

export default {
    install: Vue => {
        const defaultOptions = {
            baseURL: Vue.prototype.$config.APIServerDetails.Url,
            headers:{
                Authorization: 'Bearer ' + "token"
            }
        }


        Vue.prototype.ApiService = new ApiService(defaultOptions);
    }
}