import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import IdleVue from 'idle-vue'
import router from "../router";
import VueSession from 'vue-session'
import '@mdi/font/css/materialdesignicons.css'
import Vuetify from 'vuetify'
import ThemesService from "./services/ThemesService"
import languageService from "./services/languageService"
import FlagIcon from 'vue-flag-icon'
import axios from 'axios';
import ApiService from '../Base/services/ApiService'
import mgr from './identityserver/security'

axios.get("appsettings.json").then((response) => {
	Vue.prototype.$config = response.data;

	Vue.use(ApiService);

	Vue.use(mgr);

	Vue.use(FlagIcon);

	Vue.config.productionTip = false
	Vue.prototype.$userSession = null

	Vue.use(router);
	Vue.use(VueSession);

	if(response.data.OpenIdConfig.idleTimeout !== 0) {
		Vue.use(IdleVue, {
			eventEmitter: new Vue(),
			idleTime: response.data.OpenIdConfig.idleTimeout
		})
	}

	Vue.use(Vuetify, {
		iconfont: 'mdi'
	})

	Vue.mixin(ThemesService);
	Vue.mixin(languageService);

	new Vue({
		vuetify,
		router,
		render: h => h(App),
		mounted(){
			this.$vuetify.theme.dark = true;
			
			if(!this.$session.exists()){
				this.$session.start()
			}
		}
	}).$mount('#app')
});