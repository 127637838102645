import Vue from 'vue'

let themeGlobal = new Vue({
	data: { 
        $redirectUri: null,
        $userTheme: null
	}
});

export default {
    methods: {
        importTheme(themeName) {
            import(`../../assets/Themes/${themeName}.scss`).then( () => {
					
            }).catch( () => {
                import("../../assets/Themes/Default.scss").then( () => {
					
                }).catch( () => {
                    throw("Error Importing Default Theme At '../../assets/Themes/Default.scss', Does This File Exist?")
                });
            });
        }
    },
    computed: {
        $setTheme: {
            get: function () {
                return themeGlobal.$data.$userTheme;
            },
            set: function (setRole) { 
                themeGlobal.$data.$userTheme = setRole;
            }
        },
        $setUrl: {
            get: function () {
                return themeGlobal.$data.$redirectUri;
            },
            set: function (setRole) {
                this.importTheme(setRole);
                themeGlobal.$data.$redirectUri = setRole;
            }
        },
    },
    created() {
        if(themeGlobal.$data.$userTheme !== null) {
            this.importTheme(themeGlobal.$data.$userTheme);
        }
        else if(themeGlobal.$data.$redirectUri !== null) {
            this.importTheme(themeGlobal.$data.$redirectUri);
        }
        else {
            this.importTheme("Default");
        }
    }
};