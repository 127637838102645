<template>
	<v-dialog persistent v-model="isVisible" :max-width="styleSettings.width" :style="{ z: styleSettings.z }">
		<v-card>
			<v-toolbar dark color="#1b526d" dense flat>
				<v-toolbar-title class="white--text">{{ title }}</v-toolbar-title>
			</v-toolbar>
			<v-card-text v-show="message" class="pa-4">{{ message }}</v-card-text>
			<v-card-actions v-if="topLevel" class="pt-0">
				
				<v-btn color="#2d7da5" text @click.native="returnHome"><b>Return To Home</b></v-btn>
				<v-spacer></v-spacer>
				<v-btn color="#2d7da5" text @click.native="ignoreError"><b>Ignore</b></v-btn>
				<v-spacer></v-spacer>
				<v-btn color="#2d7da5" text @click.native="returnLogin"><b>Logout</b></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	data: () => ({
		isVisible: false,
		resolve: null,
		message: null,
		title: null,
		styleSettings: {
			color: 'primary',
			width: 350,
			z: 200
		},
		topLevel: null
	}),
	methods: {
		open(title, message, topLevel) {
			this.topLevel = !topLevel
			this.isVisible = true
			this.title = title
			this.message = message
			return new Promise((resolve) => {
				this.resolve = resolve
			})
		},
		returnHome() {
			this.isVisible = false
			this.resolve(function() {
				location.reload()
			})
		},
		ignoreError() {
			this.isVisible = false
			this.resolve(() => {
				this.reset();
			});
		},
		returnLogin() {
			this.mgr.signoutRedirect()
		},
		reset() {
			this.resolve = null,
			this.message = null,
			this.title = null
		}
	}
}
</script>