<template>
	<v-app>
		<router-view name="root">
			
		</router-view>
		<ErrorDialog class="logOutModal" ref="ErrorDialog"></ErrorDialog>
		<InactiveDialog class="logOutModal" ref="InactiveDialog"></InactiveDialog>
	</v-app>
</template>

<style>
  @import '~@mdi/font/css/materialdesignicons.min.css';
  @import '~material-design-icons/iconfont/material-icons.css';
  @import '~typeface-roboto';
  
</style>

<script>

import ErrorDialog from "./components/ErrorDialog.vue"
import InactiveDialog from "./components/InactiveDialog.vue"

export default {
	name: 'App',
	components: {
		ErrorDialog,
		InactiveDialog
	},
	methods: {
		onError(error) {
			const dialogTitle = "An Error Occured"
			const dialogMessage = error
			this.$refs.ErrorDialog.open(dialogTitle,dialogMessage, this.inIframe()).then((confirm) => {
				if(confirm) {
					confirm()
				}
			})
		},
		inIframe() {
			try {
				return window.self !== window.top;
			} catch {
				return true;
			}
		}
	},
	errorCaptured(error) {
		this.onError(error)
	},
	onIdle() {
		this.$refs.InactiveDialog.open().then((confirm) => {
			if(confirm) {
				this.mgr.signoutRedirect()
			}
		});
	},
	created() {
		// error captured hook of vue will not catch all types of exceptions

		var self = this
		window.addEventListener('unhandledrejection', function(event) {
			self.onError(event.reason)
		});
		// add listener for silent token refresh from child iframe
		if(this.$config.useLegacyAuth)
		{
			window.addEventListener("message",(e) => {
				if(e.data !== "Silent") {
					return;
				}
				this.mgr.getUser().then( (user) => {
					this.ApiService.setDefaultToken(user.access_token);
				});
			}, false);
		}
		if(this.inIframe()) {
			window.addEventListener('message', (event) => {
				var msg = null;
				try {
					msg = JSON.parse(event.data);
				}
				catch {}
				if(msg !== null) {
					if(msg.action === "lang") {
						this.updateLanguage(msg.lang);
					}
				}
				
			}, false);
		}
	},
	beforeMount() {
		// allow requests to the silent and normal login callbacks
		if(this.$config.useLegacyAuth)
		{
			if( window.location.href.includes( this.$config.OpenIdConfig.redirect_silent ) || window.location.href.includes( this.$config.OpenIdConfig.redirect ) ) {
				return;
			}
			// redirect to home with user data provided in query string -> its faster then waiting for identity to validate user stored in session
			if(window.location.search != ""){
				const params = new URLSearchParams(window.location.search)
				var userData = false
				try {
					userData = JSON.parse(params.get("data"))
				} catch { }
				if(userData) {
					this.ApiService.setDefaultToken(userData.access_token);

					this.$router.push({name: 'home', params: {userCreds:userData}});
				}
			}
			else {
				// use a default user provided in config or pull from session storage
				if(this.$config.DevSettings.ENABLED && Object.keys(this.$config.DevSettings.TESTUSER).length === 0) {
					this.$router.push({name: 'home', params: {userCreds:this.$config.DevSettings.TESTUSER}});
				}
				else if(this.$config.DevSettings.ENABLED) {
					if("Theme" in this.$config.DevSettings.TESTUSER.profile) {
						this.$setTheme = this.$config.DevSettings.TESTUSER.profile.Theme;
					}
					this.ApiService.setDefaultToken(this.$config.DevSettings.TESTUSER.access_token);
					this.$router.push({name: 'home', params: {userCreds:this.$config.DevSettings.TESTUSER}});
				}
				else {
					this.$router.push({name: 'loading'});
				}
			}
		}
		else{
			if( window.location.href.includes( this.$config.WhodisOpenIdConfig.silent_redirect_uri ) || window.location.href.includes( this.$config.WhodisOpenIdConfig.redirect_uri ) ) {
				return;
			}
			// redirect to home with user data provided in query string -> its faster then waiting for identity to validate user stored in session
			if(window.location.search != ""){
				const params = new URLSearchParams(window.location.search)
				var userData2 = false
				try {
					userData2 = JSON.parse(params.get("data"))
				} catch { }
				if(userData2) {
					this.ApiService.setDefaultToken(userData2.access_token);

					this.$router.push({name: 'home', params: {userCreds:userData2}});
				}
			}
			else {
				// use a default user provided in config or pull from session storage
				if(this.$config.DevSettings.ENABLED && Object.keys(this.$config.DevSettings.TESTUSER).length === 0) {
					this.$router.push({name: 'home', params: {userCreds:this.$config.DevSettings.TESTUSER}});
				}
				else if(this.$config.DevSettings.ENABLED) {
					if("Theme" in this.$config.DevSettings.TESTUSER.profile) {
						this.$setTheme = this.$config.DevSettings.TESTUSER.profile.Theme;
					}
					this.ApiService.setDefaultToken(this.$config.DevSettings.TESTUSER.access_token);
					this.$router.push({name: 'home', params: {userCreds:this.$config.DevSettings.TESTUSER}});
				}
				else {
					this.$router.push({name: 'loading'});
				}
			}
		}
	}
};
</script>
