<template>
	<v-dialog persistent v-model="isVisible" :max-width="styleSettings.width" :style="{ z: styleSettings.z }">
		<v-card>
			<v-toolbar dark color="#1b526d" dense flat>
				<v-toolbar-title class="white--text">Session Timeout</v-toolbar-title>
			</v-toolbar>
			<v-card-text class="pa-4 text-center justify-center">You will be logged out in:</v-card-text>
			<v-card-title v-show="countdown" class="pa-4 text-center justify-center">{{ countdown }}</v-card-title>
			<v-card-actions class="pt-0">
				<v-btn color="#2d7da5" text @click.native="cancelLogout"><b>Cancel</b></v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>

export default {
	data: () => ({
		isVisible: false,
		countdown: 10,
		resolve: null,
		message: null,
		title: null,
		styleSettings: {
			color: 'primary',
			width: 350,
			z: 200
		}
	}),
	methods: {
		open() {
			this.countdown = 10
			var self = this
			var countdownInterval = setInterval(function() {
				self.countdown = self.countdown - 1
				if(self.countdown === 0) {
					clearInterval(countdownInterval)
					self.resolve(true)
				}
			}, 1000);

			this.isVisible = true
			return new Promise((resolve) => {
				this.resolve = resolve
			})
		},
		cancelLogout() {
			this.isVisible = false
			this.resolve(false)
		},
		reset() {
			this.resolve = null
		}
	}
}
</script>