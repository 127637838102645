import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

export default new Router({
	mode: "history",
	base: process.env.BASE_URL,
	routes: [
		{
			path: "",
			redirect:"/Home",
		},
		{
			path: "/Callback",
			name: "callback",
			components:{
				root: () => import("./Base/identityserver/Callback.vue")
			}
		},
		{
			path: "/Silent",
			name: "silent",
			components:{
				root: () => import("./Base/identityserver/Silent.vue")
			}
		},
		{
			path: "/return",
			name: "return",
			components:{
				root: () => import("./Base/identityserver/return.vue")
			}
		},
		{
			path: "/renew",
			name: "renew",
			components:{
				root: () => import("./Base/identityserver/renew.vue")
			}
		},
		{
			path: "/Loading",
			name: "loading",
			props: {
				root: true
			},
			components: {
				root: () => import("./Base/components/Loading.vue")
			},
		},
		{
			path: "/Home",
			name: "home",
			props: {
				root: true
			},
			components: {
				root: () => import("./views/Messaging.vue")
			}
		},
		{ path: "/Denied", name: "denied", props: { root: true }, components: { root: () => import("./views/Denied.vue") }},
	]
});