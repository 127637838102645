import languageManifest from '../../Languages/Manifest.json'
import Vue from 'vue'

let langGlobal = new Vue({
	data: { 
		$language: require('../../Languages/' + languageManifest["Master Language"])
	}
});

export default {
    methods: {
        inIframe() {
            try {
                return window.self !== window.top;
            } catch {
                return true;
            }
        },
        updateLanguage(newLanguageName) {
            if(!this.inIframe()) {
                var iframeElement = document.getElementById("iframe");
                if(iframeElement !== null && iframeElement.src !== "about:blank") {
                    let iframeWindow = iframeElement.contentWindow;
                    var msgStr = JSON.stringify({action: "lang", lang: newLanguageName});
                    
                    const url = new URL(iframeElement.src);

                    iframeWindow.postMessage(msgStr, url.origin);
                }
            }
            var newLanguageManifest = languageManifest["Target Languages"].find( availableLang => {
                return newLanguageName === availableLang["Language"];
            });
            if(newLanguageManifest !== undefined) {
                this.$text = newLanguageManifest["File"];
            }
            else {
                this.$text = languageManifest["Master Language"];
            }
        }
    },
    computed: {
        $text: {
            get: function () {
                return langGlobal.$data.$language;
            },
            set: function (newLang) { 
                langGlobal.$data.$language = require('../../Languages/' + newLang);
            }
        },
    },
};